/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from './layout';
// @ts-ignore
import Hero from '../texts/hero';
// @ts-ignore
import Bottom from '../texts/bottom';
import Title from './title';
import Listing from './listing';
import List from './list';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import replaceSlashes from '../utils/replaceSlashes';

type PostsProps = {
    posts: {
        slug: string;
        title: string;
        date: string;
        excerpt: string;
        description: string;
        timeToRead: number;
        tags?: {
            name: string;
            slug: string;
        }[];
    }[];
};

const Homepage = ({ posts }: PostsProps) => {
    const { basePath, blogPath } = useMinimalBlogConfig();

    return (
        <Layout>
            <Listing posts={posts} showTags={false} />
            <List>
                <Bottom />
            </List>
        </Layout>
    );
};

export default Homepage;
